<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW PERSONAL LOAN</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-layout row wrap class="align-center mx-2 ">
          <v-flex xs12 md3>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Selection"
              :items="['Employee','Non-Employee']"
              @change="selected"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="selection==='Employee'">
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-text="category"
              item-value="id"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 v-if="selection==='Employee'">
            <v-autocomplete
              dense
              outlined
              v-model="search"
              :items="search_items"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
              @change="get_search_items_info"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2" v-if="selection==='Employee'">
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="branch"
              label="Branch"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2" v-else>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="branch"
              label="Branch"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-9">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="purpose"
              label="Purpose"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="date"
              label="Date"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="terms"
              label="Terms (months)"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="interest"
              label="Interest"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="monthly_due"
              label="Monthly Ammortization"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="half_monthly_due"
              label="Half of the Month Ammortization"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="total_interest"
              label="Interest Aquired"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="total"
              label="Total Loan"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <!-- alert -->
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            class="me-3 mt-4"
            @click="save_employee"
            v-if="!saving"
          >
            Save changes
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      selection: '',
      category_id: '',

      search: '',
      search_items: [],
      isLoading: false,

      name: '',
      branch: '',

      total: 0,
      total_interest: 0,
      half_monthly_due: 0,
      monthly_due: 0,
      interest: 0,
      terms: 0,
      purpose: '',
      date: '',
      amount: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      ...mapActions('employee', ['search_active_employee',]),
      ...mapActions('loan_application', ['register_loan_application']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      initialize_data() {
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected() {
        this.employee_id = 0
      },
      selected_category() {
        this.name = ''
        this.branch = ''
        this.search_items = []
        this.search = ''
        this.employee_id = 0
      },
      searching(value) {
        this.name = ''
        this.branch = ''
        this.employee_id = 0

        this.isLoading = true
        this.search_active_employee({
          search_word: value,
          category_id: this.category_id,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.employee_id = this.search_items[index].id
            this.name = this.search_items[index].name
            this.branch = this.search_items[index].details.branch.branch_code
          } else {
            this.name = ''
            this.branch = ''
            this.employee_id = 0
          }
        } else {
          this.name = ''
          this.branch = ''
          this.employee_id = 0
        }
      },
      calculation() {
        var to_iner = parseFloat(this.terms) * parseFloat(this.interest)
        this.total_interest = (parseFloat(this.amount) * (parseFloat(to_iner) / 100))
        this.total = parseFloat(this.amount) + parseFloat(this.total_interest)
        this.monthly_due = parseFloat(this.total) / parseFloat(this.terms)
        this.half_monthly_due = parseFloat(this.monthly_due) / 2
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('employee_id', this.employee_id);
          data.append('amount', this.amount);
          data.append('name', this.name.toUpperCase());
          data.append('date', this.date);
          data.append('months_to_pay', this.terms);
          data.append('payment_per_month', this.monthly_due);
          data.append('period_amount', this.half_monthly_due);
          data.append('total', this.total);
          data.append('purpose', this.purpose.toUpperCase());
          this.register_loan_application(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving = false
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
